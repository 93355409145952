@import "../../_globalColor";

.projects-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns per row */
  gap: 1.5rem; /* Adjust gap between the cards */
}
@media (max-width: 700px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.project-detail {
  padding: 15px 0;
  text-align: center;
}

.card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.card-skills {
  font-size: 14px;
  color: $skillsColor;
  margin-bottom: 10px;
}

.card-subtitle {
  font-size: 16px;
  line-height: 1.8;
}

.project-card {
  display: flex;
  flex-direction: column;
  background-color: $lightBackground2;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid $lightBorder1;
  justify-content: center;
  min-height: 350px;
}

.project-card-dark {
  background-color: $darkBackground;
}

.project-card:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}
